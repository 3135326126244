const Home = () => {
    return (
        <>
            <h2>Lorem ipsum dolor sit amet.</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                harum, numquam odio obcaecati nisi perferendis aliquid aliquam
                nobis sapiente autem dolores at non. Nobis, omnis!
            </p>

            <a href="/pricing">Get in touch</a>
        </>
    );
};

export default Home;
