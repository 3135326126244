import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { About, Home, Pricing, Navbar } from './pages';

const App = () => {
    return (
        <>
            <Navbar />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/pricing" element={<Pricing />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
