const Navbar = () => {
    return (
        <>
            <nav className="nav">
                <a href="/" className="logo">
                    Lastacruz
                </a>
                <ul>
                    <li>
                        <a href="/home">Home</a>
                    </li>
                    <li>
                        <a href="/about">About</a>
                    </li>
                    <li>
                        <a href="/pricing">Pricing</a>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Navbar;
